@tailwind base;
@tailwind components;
@tailwind utilities;

/* 64px here represents navbar height which has a fixed height of 64 pixels 
 * no matter what is the screen size*/
.hero-height {
  min-height: calc(100vh - 64px);
}

@layer components {
  .section-title {
    @apply pb-10 pt-16 text-center text-2xl font-bold md:text-3xl lg:pb-16 lg:pt-28 lg:text-4xl;
  }
}
